.documents-container {
    // padding: 1rem 5rem 1rem 0rem;
    background-color: #fff;
    border: 1px solid #808080;
    border-top: none;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;

    h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        margin: 0;
    }

    a {
        margin-top: 5px;
    }

    .documents-table-header {
        height: 50px;
        background-color: #f0f0f0;
        padding: 0 1rem;

        .header-search-input {
            height: 35px;
            background: #d9d9d9;
            border-radius: 5px;
            border-bottom: none;
            width: 100%;

            &::before,
            &::after {
                border-bottom: none;
            }

            &:hover {
                &::before,
                &::after {
                    border-bottom: none;
                }
            }

            input {
                padding: 0 10px;
            }
        }
    }

    .filename-wrapper {
        // padding-left: 10%;
        > div {
            margin: 0 0.5rem;
        }

        > span {
            width: fit-content;
        }

        input[type="radio"] {
            span {
                background-color: #363eff;
            }
        }
    }

    .download-button {
        width: 90%;

        button {
            width: 100%;
            background-color: #5374c9;
            text-transform: capitalize;
        }
    }

    .document-item {
        background: rgb(255 255 255 / 3%);
        border: 0.7px solid #ececec;
        box-shadow: 3px 3px 33px rgb(0 0 0 / 4%);
        border-radius: 9px;
        padding: 1rem;
        font-weight: 600;
        font-size: 1rem;
        color: #373737;
        margin-left: 1rem;
        margin-bottom: 0.5rem;
        cursor: pointer;

        .file-logo {
            width: 1.5rem;
            height: auto;
            margin-right: 0.2rem;
        }

        .file-icon {
            width: 1.5rem;
            height: auto;
            margin-right: 0.2rem;
            color: #ecaa00;
        }
    }

    .delete-icon {
        cursor: pointer;
        color: #dc2855;
    }

    .profile-icon {
        width: 1.5rem;
        height: auto;
        margin-right: 0.2rem;
        color: #6859f9;
        cursor: pointer;
    }

    .edit-icon {
        width: 1.5rem;
        height: auto;
        cursor: pointer;
        color: #6859f9;
    }

    .no-documents {
        text-align: center;
        font-size: 1rem;
        font-weight: 700;
        width: 100%;
        color: #414141;
        margin: 1rem 0;
    }
}

.delete__image {
    max-width: 80%;
    max-height: 40vh;
    object-fit: cover;
}
