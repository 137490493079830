@import "src/styles/variables";

.profile-details__count-card__container,
.profile-details__count-card__container--selected {
    box-shadow: 4px 4px 44px rgba(0, 0, 0, 0.04);
    background-color: white;
    border-radius: 0.5rem;
    padding: 2rem 1rem;
    position: relative;
    height: 100%;
    cursor: pointer;

    p {
        font-size: 1.2rem;
        font-weight: 600;
    }
    .comment-icon,
    .initiate-icon,
    .express-icon {
        width: 2rem;
        height: auto;
    }
    .project-details__count-card__count {
        margin: 1rem 0;
        font-style: normal;
        font-weight: 700;
        font-size: 3rem;
        color: #373737;
        height: 3.5rem;
    }

    svg {
        width: 2rem;
        height: auto;
        color: $color--primary;
    }

    .fav__icon {
        color: #d70808;
    }

    .comment__icon {
        color: #00763d;
    }

    .profile-details__count-card__circle {
        $circle-size: 4rem;
        position: absolute;
        top: 0;
        right: 0;
        width: $circle-size;
        height: $circle-size;
        background: lighten($color: $color--primary, $amount: 16);
        $border-radius: 0 0.5rem 0 $circle-size;
        border-radius: $border-radius;
        -moz-border-radius: $border-radius;
        -webkit-border-radius: $border-radius;
    }
}

.profile-details__count-card__container--selected {
    background: $color--primary;
    color: #fff !important;

    .project-details__count-card__count {
        color: #fff !important;
    }

    svg {
        color: #fff !important;
    }
}

.project__title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
}
