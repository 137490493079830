.header {
    margin: 0.5rem;
    .header__text {
        font-style: normal;
        font-weight: 600;
        font-size: 1.25rem;
        color: #111111;
    }

    .nav__btn {
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        color:black;
        padding: 3px 11px;
        width: 111px;
        height: 38px;
        border-radius: 5px;
        text-transform: capitalize;
    }

    .active{
        background-color: #6859f9;
        color: white;
    }
}
