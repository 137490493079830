#financials-participation__container {
    .title {
        padding-left: 1rem;
    }

    #left__container {
        padding-left: 1rem;
        position: relative;
        z-index: 20;
    }

    .input__fields {
        .field {
            margin-bottom: 1.2rem;
        }
    }

    .error {
        color: #cf3232;
    }

    .field {
        margin-bottom: 1.2rem;

        .MuiFormLabel-root {
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 2.3rem;
            color: #373737;
        }

        .MuiTextField-root {
            padding: 0;
            border-radius: 8px;

            & .MuiOutlinedInput-root {
                border: 0.8px solid #d9d9d9;
                border-radius: 4.20456px;
                min-height: 0;
                height: 2.75rem;
                width: 90%;

                &.Mui-focused {
                    border: 1px solid #aaa;
                }
            }

            .MuiFormLabel-filled {
                font-size: 1.3rem;
            }

            input {
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                color: #272727;
            }

            fieldset {
                border: none;

                legend {
                    span {
                        color: #7b7b7b;
                    }
                }
            }
        }
    }

    .MuiSelect-select {
        min-height: 0;
        padding: 10px;
        border: 0.8px solid #d9d9d9;
        border-radius: 4.20456px;

        &:hover,
        &:active {
            border: 1px solid #aaa;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border: 0.8px solid #d9d9d9;

        &:hover,
        &:active {
            border: 1px solid #aaa;
        }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    .textarea__container {
        position: relative;
        z-index: 20;

        label {
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 2.3rem;
            color: #373737;

            span {
                font-size: 0.8rem;
                font-weight: 500;
            }
        }

        textarea {
            border: 1px solid #d7d7d7;
            border-radius: 4px;
            padding: 8px;
        }
    }

    .escalations__container {
        position: relative;
        z-index: 20;
        padding-left: 1rem;
        margin-top: 2rem;

        label {
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 2.3rem;
            color: #373737;

            span {
                font-weight: 500;
                font-size: 1rem;
                color: #0047ff;
            }
        }
    }
}
