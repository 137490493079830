.profile {
    margin-top: 1.5rem;
    padding: 0 2rem;

    .title {
        margin-top: 1rem;
        font-weight: 700;
        width: 13rem;
        text-align: center;
        font-size: 2rem;
        color: #414141;
    }

    .main-profile {
        width: 100%;
        min-height: 50vh;
        background-color: white;
        padding: 2rem;
        margin-bottom: 2rem;
        border-radius: 1rem;
        box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);

        .profile-summary__details {
            padding: 0.5rem;
            margin-right: 10%;
            position: relative;
            background-color: rgb(255 255 255 / 100%);
            border-radius: 12px;
            border: 1px solid rgb(236 236 236);

            .avatar {
                width: 7rem;
                height: auto;
            }
        }

        .tab {
            border-radius: 6px;
            font-weight: 600;
            font-size: 0.8rem;
            text-align: center;
            padding: 0.5rem 1rem;
            margin-right: 0.5rem;
            cursor: pointer;
        }

        .active {
            background: #6859f9;
            color: white;
        }

        .card-container {
            padding: 1rem 2rem;
            margin-bottom: 1rem;
        }

        .card {
            box-shadow: 4px 4px 44px rgb(0 0 0 / 4%);
            border-radius: 12px;
            margin: 0 1rem;
            padding: 2rem 1rem;

            h4 {
                padding: 0.5rem 0;
                font-weight: 700;
                font-size: 2rem;
            }

            h5 {
                font-weight: 600;
                font-size: 1.3rem;
            }

            svg {
                width: 2rem;
                height: auto;
                color: #ff3131;
            }

            &.active {
                background: #6859f9;
                color: #fff !important;

                svg {
                    color: #fff !important;
                }
            }
        }

        h6 {
            font-weight: 600;
            font-size: 1.3rem;
            color: #414141;
            padding-bottom: 1rem;
        }

        .project-container {
            margin: 0.5rem 0;
            margin-top: 25px;

            .project-info {
                padding: 1rem;
                margin-top: 20px;
                border-radius: 15px;
                background: linear-gradient(0deg, #fff, #fff);

                .project-name {
                    font-weight: 600;
                    font-size: 1rem;
                    color: #414141;
                }

                .project-detail {
                    font-weight: 500;
                    font-size: 0.9rem;
                    padding: 1rem 0;
                    color: #414141;
                }

                .btn-content {
                    .btn {
                        font-weight: 600;
                        font-size: 0.8rem;
                        text-transform: capitalize;
                        color: #6859f9;
                        border: 1px solid #6859f9;
                        margin: 0 0.5rem;

                        &.filled {
                            color: #fff;
                            background-color: #6859f9;
                        }
                    }
                }

                .location {
                    background: rgb(104 89 249 / 3%);
                    border-radius: 12px;
                    padding: 0.2rem 1rem;

                    .location-icon {
                        width: 1.5rem;
                        height: auto;
                        color: #6859f9;
                        padding: 0.5rem;
                    }

                    .location-title {
                        font-weight: 500;
                        font-size: 0.8rem;
                        color: #000;
                    }

                    .location-value {
                        font-weight: 600;
                        font-size: 0.7rem;
                        color: #000;
                    }
                }
            }
        }
    }
}

.popper {
    background: #fdfdfd;
    margin-top: 1rem;
    min-width: 30vw;
    border: 1px solid rgb(104 89 249 / 15%);
    box-shadow: 0 4px 52px 2px rgb(168 168 168 / 12%);
    border-radius: 12px;
    padding: 2rem;

    .input-share {
        background: #f7f7f7;
        border-radius: 8px;
    }

    .copy-btn {
        background: #6859f9;
        border-radius: 4.6768px;
        font-weight: 800;
        font-size: 0.6rem;
        color: #fff;
        text-transform: capitalize;

        &:hover {
            background-color: #6859f9;
        }

        .copy-icon {
            width: 1rem;
            padding-left: 0.5rem;
            height: auto;
        }
    }

    .social-container {
        margin: 1rem 0;

        .social-icon {
            width: 1.8rem;
            height: auto;
        }

        .social-title {
            padding-top: 0.2rem;
            font-weight: 500;
            font-size: 0.7rem;
            letter-spacing: 0.187177px;
            color: #555;
        }
    }
}
