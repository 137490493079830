@page {
    margin: 0;
    size: landscape;
}

@media print {
    .tabs-content {
        break-inside: avoid;
    }
}

.project_report_container {
    padding: 2.5rem;

    h5 {
        font-size: 1rem;
        font-weight: 500;
        white-space: nowrap;
    }

    .header {
        img {
            height: 4rem;
            width: 4rem;
        }

        .header-textarea {
            h5 {
                font-style: normal;
                font-weight: 600;
                font-size: 1.2rem;
                margin-bottom: 0.5rem;
            }

            .address-wrapper {
                h6 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.2rem;
                    margin-right: 0.5rem;
                    color: #000;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    color: #606060;
                }
            }
        }
    }

    .tabs-header {
        background: #6859f9;
        border-radius: 12px;
        padding: 1rem 0;
        padding-left: 3rem;
        margin: 1rem 0;

        p {
            font-style: normal;
            font-weight: 800;
            font-size: 1.2rem;
            line-height: 1.2rem;
            color: white;
        }
    }

    .tabs-content {
        border-bottom: 1px solid #cfcfcf;
        padding: 0.5rem 0;
        padding-left: 3rem;

        h5,
        p {
            margin: 0;
        }
    }

    .detailed-info {
        margin-top: 1.5rem;

        p {
            min-width: none;
        }
    }
}

.print-btn {
    margin: 1rem;
    padding: 0.8rem 2rem;
    border-radius: 0.5rem;
    text-transform: capitalize;
    background: #6859f9;
    color: white;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
    border: none;
    cursor: pointer;

    &:hover {
        background: #4a3f9a;
    }
}
