.action__btns {
    .outlined-btn {
        text-transform: capitalize;
        margin: 0.2rem !important;
        background-color: #6859f9 !important;
        padding: 0.3rem 1 rem !important;
        color: #fff !important;
        white-space: nowrap;
        width: 100%;
        margin: auto;

        .edit-icon {
            color: #fff;
            width: 1.2rem;
            height: 1.2rem;
            margin-right: 0.2rem;
        }
    }

    .status_btn {
        width: 80%;
        text-transform: capitalize;
        margin: 0.5rem 0 !important;
        background-color: #6859f9 !important;
        padding: 0.3rem 1 rem !important;
        color: #fff !important;
        width: 100%;
        margin: auto;
        font-size: 0.8rem;
    }
}
