.contact_us_page {
    .contact_us_header {
        padding: 3rem 0;
        padding-bottom: 4rem;
        background: linear-gradient(180deg, #6859f9 0%, #5986f9 100%);
        color: white;
        text-align: center;
        > p,
        > h1 {
            line-height: 40px;
        }
        h1 {
            margin-top: 1.2rem;
            margin-bottom: 2rem;
            font-size: 2.5rem;
        }
    }
    .contact_us_form {
        min-height: 700px;
        width: 700px;
        position: relative;
        top: -2rem;
        background: #fff;
        border-radius: 20px;
        padding: 2rem 4rem;
    }
    .contact_us_form_items {
        margin-bottom: 1rem;
        label {
            font-weight: 600;
            line-height: 30px;
        }
    }
    .submit_button {
        height: 72px;
        background: #6859f9;
        font-weight: 600;
        margin: 3rem 0;
    }
}
