.select-field {
    padding: 12.3588px;
    width: 179px;
    height: 42.72px;
    background: #fff;
    font-family: Poppins;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 18px;
    color: #565656;
}

.change-user-role__container {
    max-width: 75%;
}

.filter-select-wrapper {
    .MuiInputLabel-root {
        background-color: white;
        padding: 0 5px;
    }
}

.expTable {
    th {
        font-family: Poppins;
        font-weight: 600;
        font-size: 0.8rem;
        line-height: 18px;
        color: #565656;
    }

    td {
        font-family: Poppins;
        font-weight: normal;
        font-size: 0.8rem;
        line-height: 18px;
        color: #818181;
    }
}
