#confirm-email__container {
    max-width: 400px;
    border: none;
    width: 100vw;
    margin: auto;
    background-color: white;
    padding: 1rem 1rem;
    text-align: center;
    margin-top: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #d8dee7;

    h4 {
        text-align: center;
        margin: 2rem 0;

        .status-logo {
            width: 6rem;
            height: auto;
        }
    }

    h5 {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 1.1rem;
        letter-spacing: 0.01em;
        color: #4f4f4f;
    }

    .btn {
        background: #20b038;
        border-radius: 12px;
        margin: 2rem 0;
        color: #fff;
        padding: 1rem 2rem;
        text-transform: capitalize;
    }

    .btn--failure {
        background-color: #b02020;
        border-radius: 12px;
        margin: 2rem 0;
        text-transform: capitalize;
        color: #fff;
        padding: 1rem 2rem;
    }
}
