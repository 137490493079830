.modal {
    z-index: 10000 !important;
}
.MuiPopover-root {
    z-index: 99999;
}
.modal__container {
    .modal-dialog {
        min-width: 600px;
        
        .modal-content {
            z-index: -99999;

            border: none;
            overflow: hidden;
        }
    }

    .close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        outline: none;
        background-color: transparent;
        color: #3f444a;
        font-weight: 300;
    }

    .modal-header {
        background: rgb(255, 255, 255) !important;
        text-transform: capitalize !important;
        padding: 1rem 0;
        color: rgb(0, 0, 0);
        font-size: 1.1rem;
        letter-spacing: 1px;
    }

    .label {
        font-weight: 600;
        font-size: 1rem;
        line-height: 24px;
        color: #373737;
        margin-bottom: 0.5rem;
    }

    input {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        color: #272727;
    }

    .MuiSelect-select {
        min-height: 0;
        padding: 10px;
        border: 0.8px solid #d9d9d9;
        border-radius: 4.20456px;

        &:hover,
        &:active {
            border: 1px solid #aaa;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border: 0.8px solid #d9d9d9;

        &:hover,
        &:active {
            border: 1px solid #aaa;
        }
    }

    a.transparent-button {
        text-decoration: none;
        color: #e33066;
    }

    // button.transparent-button {
    //     border: none;
    //     outline: none;
    //     background-color: transparent;
    //     color: #e33066;
    //     padding: 0;
    // }

    // footer.modal-footer {
    //     border-top: solid 1px #eee;
    //     padding: 1rem 0;
    //     display: flex;
    //     justify-content: space-between;
    //     font-size: 0.9rem;
    //     font-weight: 300;
    // }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    // .participate-btn {
    //     background: #6859f9;
    //     border-radius: 5.2717px;
    //     font-weight: 600;
    //     padding: 0.5rem 1rem;
    //     font-size: 0.8rem;
    //     line-height: 18px;
    //     color: #fff;

    //     &:hover,
    //     &:active {
    //         background: #6859f9;
    //     }
    // }
}

.LoginModal {
    .modal-dialog {
        .modal-content {
            border: none;
            overflow: hidden;
        }
    }

    .close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        border: none;
        outline: none;
        background-color: transparent;
        color: #3f444a;
        font-weight: 300;
    }

    .modal-header {
        background: #6859f9;
        text-transform: capitalize !important;
        display: flex;
        justify-content: center;
        padding: 1rem 0;
        color: #fff;
        font-size: 1.1rem;
        letter-spacing: 1px;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
        margin-bottom: 2rem;

        * {
            font-weight: 300;
            font-size: 0.9rem;
        }

        small {
            display: block;
            font-size: 0.7rem;
            line-height: 1.2;
            margin-top: 0.2rem;
        }

        // form elements
        input,
        select {
            border-radius: 0;

            &:focus {
                border-color: #ced4da;
                box-shadow: none;
            }
        }

        .submit-container {
            display: flex;
            justify-content: center;

            .submit {
                background: #6859f9;
                border: none;
                outline: none;
                padding: 0.2rem 1rem;
                font-size: 0.9rem;
                display: flex;
                gap: 0.1rem;
            }
        }
    }

    .label {
        font-weight: 600;
        font-size: 1rem;
        line-height: 24px;
        color: #373737;
        margin-bottom: 0.5rem;
    }

    input {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        color: #272727;
    }

    .MuiSelect-select {
        min-height: 0;
        padding: 10px;
        border: 0.8px solid #d9d9d9;
        border-radius: 4.20456px;

        &:hover,
        &:active {
            border: 1px solid #aaa;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border: 0.8px solid #d9d9d9;

        &:hover,
        &:active {
            border: 1px solid #aaa;
        }
    }

    a.transparent-button {
        text-decoration: none;
        color: #e33066;
    }

    button.transparent-button {
        border: none;
        outline: none;
        background-color: transparent;
        color: #e33066;
        padding: 0;
    }

    footer.modal-footer {
        border-top: solid 1px #eee;
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;
        font-size: 0.9rem;
        font-weight: 300;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    .participate-btn {
        background: #6859f9;
        border-radius: 5.2717px;
        font-weight: 600;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        line-height: 18px;
        color: #fff;

        &:hover,
        &:active {
            background: #6859f9;
        }
    }
}
