.previous-escalatons__container {
    .header {
        border-top: 2px solid #d7d7d7;
        border-bottom: 2px solid #d7d7d7;
        padding: 8px;

        .header-text {
            font-weight: 500;
            font-size: 0.8rem;
            margin-left: 20px;
            text-align: center;
            color: black;

            .import-export__icon {
                width: 0.8rem;
                height: 0.7rem;
            }
        }
    }

    .row {
        border-bottom: 1px solid #d7d7d7;
        padding: 8px;

        .row-text {
            font-weight: 500;
            font-size: 0.8rem;
            margin-left: 20px;
            text-align: center;
            color: black;
        }
    }
}
