.project-tile__container {
    background-color: white;
    margin: 0;
    border-radius: 0.5rem;
    position: relative;

    .project-tile__scope {
        font-size: 0.9rem;
    }

    &:nth-of-type(1) {
        margin-top: 0;
    }

    .project-tile__self-or-others {
        position: absolute;
        right: 0;
        padding: 0.2rem 1rem;
        text-align: center;
        background: #ff832a;
        border-radius: 0 6px;
        font-style: normal;
        font-weight: 500;
        font-size: 0.8rem;
        color: #fff;
    }

    .project-tile__image__container {
        padding: 0;
        margin: 0;

        .img-responsive {
            height: 7.5rem;
            width: 7.5rem;
            border-top-left-radius: 6px;
        }
    }

    .project-tile__detail {
        padding: 0.5rem;

        .project-tile__name {
            font-weight: 600;
            font-size: 1rem;
            color: #000;
            text-transform: capitalize;
            margin-right: 1rem;
        }

        .project-tile__description {
            margin: 0.3rem 0;

            .project-tile-icon {
                width: 1rem;
                margin-right: 0.1rem;
                height: auto;
                color: #6859f9;
            }

            .project-tile__description__text {
                font-weight: 500;
                font-size: 0.8rem;
                margin-left: 0.5rem;
                color: #000;
                padding-right: 1rem;

                span {
                    margin-left: 0.5rem;
                }
            }
        }

        .project-tile__like__section {
            border-top: #e3e3e3 solid 1px;
            padding-top: 0.5rem;

            .project-tile-likes {
                font-weight: 500;
                font-size: 0.8rem;
                color: #000;

                .like-icon {
                    width: 1rem;
                    height: auto;
                    color: #6859f9;
                    margin-right: 0.3rem;
                    position: relative;
                    bottom: 0.1rem;
                }
            }

            .view-btn,
            .add-to-promoted__btn {
                font-weight: 600;
                font-size: 0.7rem;
                padding: 0.5rem 1rem;
                text-transform: capitalize;
                color: #fff;
                background: #6859f9;
                border-radius: 5px;
                margin-left: 1rem;
            }
        }
    }
}

.project-tile__promotion__message__input {
    margin: 1rem 0;

    input {
        color: white;

        &::placeholder {
            color: white;
        }
    }

    label {
        color: white;
    }

    fieldset {
        border: 1px solid white;
    }
}
