.project-stats__container {
    background: #ffffff;
    border: 1px solid #f3f4f6;
    border-radius: 6.93805px;
    margin: 0.5rem !important;
    margin-left: 1.5rem !important;
    margin-top: 1.5rem !important;
    .divider {
        width: 100%;
        border-color: rgba(51, 51, 51, 0.5);
    }
    .project-stats__header {
        padding: 1rem 2rem;
        padding-top: 2rem;
        .project-stats__title {
            font-family: Lato;
            font-weight: 500;
            font-size: 0.8rem;
            line-height: 21px;
            color: #494949;
        }
        .project-stats__btn {
            font-family: Lato;
            font-weight: 600;
            cursor: pointer;
            font-size: 0.7rem;
            line-height: 18px;
            color: #4154e8;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .project-stats__item {
        padding: 1rem 2rem;
        .stats-text {
            font-family: Lato;
            font-weight: 600;
            font-size: 0.7rem;
            line-height: 18px;
            color: #494949;
        }
        .stats-value {
            font-family: Lato;
            font-weight: 600;
            font-size: 0.7rem;
            line-height: 18px;
            color: #000000;
        }
    }
}
