.login-modal-content__container {
    margin-top:0.5rem;
    
    .user-login-form {
        margin: 0.5rem;

        form {
            width: 100%;
        }

        input {
            font-style: normal;
            font-weight: 400;
            color: #858585;
        }

        button {
            width: 100%;
            text-transform: capitalize;
            height: 3.5rem;

            img {
                width: 1.5rem;
            }

            &.login__btn {
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
                color: #ffffff;
                background: #6859f9;
                border-radius: 6px;
            }

            &.forgot-paswd__btn {
                font-style: normal;
                font-weight: 600;
                font-size: 0.77rem;
                color: #392c64;
                float: right;
                text-align: right;
                padding: 0;
                height: fit-content !important;
                margin: 0.5rem 0;
                margin-top: -0.3rem;
            }
        }
    }

    .field__grid {
        margin-bottom: 1rem;
    }

    .input__label {
        margin-bottom: 0.25rem;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
    }

    .MuiOutlinedInput-input {
        height: 1rem !important;
    }

    .input__field {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #858585;
    }

    .terms__text {
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        text-align: center;
        color: #7a7a7a;
        width: 100%;
        margin: 0.8rem auto;

        a {
            font-weight: 600;
            font-size: 0.8rem;
            color: #7a7a7a;
            text-decoration: none;
        }
    }

    .error-msg__container{
        margin: 0.1rem auto 0.7rem auto;
        
        .link__text{
            color: #6859F9;
            text-decoration: underline;
            cursor:pointer;
        }

        .error-para{
            text-align: center;
        }
    }
}
