.document-dialog__paper {
    min-width: 50vw !important;
    background: transparent;
    box-shadow: none;
    overflow: hidden;

    .documents-dailog__box {
        .document-close__icon {
            cursor: pointer;
            color: white;
            font-size: 2rem;
            margin: 1rem;
        }

        .document__swiper {
            max-width: 100vw;
            width: 100%;
            margin: auto;
            background-color: white;

            .swiper-slide {
                height: fit-content !important;
                max-width: 90% !important;
                margin: auto !important;
            }

            .image__slide,
            .document__slide {
                position: relative;
                max-width: 80%;
                margin: auto;

                .document__image {
                    min-height: 50%;
                    min-width: 50%;
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: fill;
                }
            }

            .image__slide {
                height: fit-content;
            }
        }

        .swiper-button-next {
            cursor: pointer;
            background: black;
            border-radius: 50%;
            width: 4.5rem;
            height: 4.5rem;
            color: white;
            margin-top: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .swiper-button-prev {
            cursor: pointer;
            background: black;
            border-radius: 50%;
            width: 4.5rem;
            height: 4.5rem;
            color: white;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-top: 0;
        }
    }
}
