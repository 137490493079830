.calender__container {
    position: relative;
    //margin: 0 0.5rem !important;
    border: 1px solid #f3f4f6;
    margin: 0 auto;
    width: 100%;
    min-height: 360px;

    .calender__text {
        position: absolute;
        top: 0;
        padding: 10px;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 0.6rem;
        line-height: 21px;
        color: #ffffff;

        span:first-child {
            font-weight: bold;
        }
        span:last-child {
            text-transform: uppercase;
            font-size: 8px;
        }
    }
    .MuiPickersToolbar-root {
        background-color: #4154e8;
        color: white;
        border-radius: 5px 5px 0 0;
        width: 100%;
        span {
            visibility: hidden;
        }
    }

    .MuiCalendarPicker-root {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 !important;
    }

    .MuiPickersCalendarHeader-root {
        display: none;
    }
    .MuiPickersToolbar-content {
        width: 100%;
        justify-content: center;
        button {
            display: none;
        }
    }
    .MuiDayPicker-header {
        background-color: #4154e8;
        margin-bottom: 10px;
        width: 100%;
        justify-content: space-between;
        span {
            color: white;
        }
    }

    .MuiDayPicker-monthContainer {
        width: 100%;
        > div {
            width: 100%;
            justify-content: space-between;
        }
    }

    .css-epd502 {
        width: 100%;
        max-width: 450px;
    }
    .MuiPickerStaticWrapper-content {
        min-width: 0px;
    }
}
