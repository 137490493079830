@import "https://fonts.googleapis.com/css2?family=Poppins&display=swap";

.sidenav__container {
    background: #fff;
    box-shadow: 4.5px 4.5px 77.86px rgb(0 0 0 / 7%);
    min-height: 100%;

    p,
    div,
    button,
    span,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: Poppins, sans-serif;
        font-style: normal;
    }

    a {
        text-decoration: none;
    }

    .MuiAccordion-root {
        &.projects-panel__accordion {
            .MuiAccordionSummary-expandIconWrapper {
                position: absolute;
                left: 40%;
            }
        }

        &.user-panel__accordion {
            .MuiAccordionSummary-expandIconWrapper {
                position: absolute;
                left: 30%;
            }
        }

        width: 100%;

        &::before {
            background: transparent;
        }

        .MuiSvgIcon-root {
            font-weight: 400;
            font-style: normal;
            color: #4154e8;
            font-size: 1rem;
        }
    }

    .dashboard__section {
        min-height: 4rem;
        width: 100%;
        margin: 0;

        .dashoard__text {
            padding: 8px 20px;
            font-weight: 600;
            font-size: 16.42px;
            color: #494949;

            // padding: 0 1rem;
            width: 90%;
            height: 43.46px;
            border-radius: 14.08px;
            text-transform: capitalize;
            justify-content: unset;

            &.selected {
                background: rgb(65 84 232 / 8%);
                font-weight: 600;
                color: #4154e8;
            }
        }
    }

    .analytics__section {
        margin-bottom: 20px;

        .dashoard__text {
            padding: 8px 20px;
            font-weight: 600;
            font-size: 16.42px;
            color: #494949;

            // padding: 0 1rem;
            width: 90%;
            height: 43.46px;
            border-radius: 14.08px;
            text-transform: capitalize;
            justify-content: unset;

            &.selected {
                background: rgb(65 84 232 / 8%);
                font-weight: 600;
                color: #4154e8;
            }
        }
    }

    .accordion-title {
        font-weight: 600;
        font-size: 1rem;
        color: #4154e8;
    }

    .MuiDivider-root {
        width: 100%;
        border-top: 1px solid rgb(0 0 0 / 20%);
        box-shadow: 4.5px 4.5px 77.86px rgb(0 0 0 / 7%);
    }

    .panel__details {
        border-left: 1.31px solid rgb(65 84 232 / 38%);
        padding-left: 0.5rem;

        a {
            font-family: Poppins, sans-serif;
            font-style: normal;
            padding: 11.8px 23.5px;
            font-weight: 600;
            font-size: 0.8rem;
            color: #494949;
            border-radius: 14.08px;
            text-transform: capitalize;
            width: 100%;

            &.active {
                background: rgb(65 84 232 / 8%);
                font-weight: 600;
                color: #4154e8;
            }
        }
    }
}
