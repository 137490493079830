#basic-information__container {
    $border-color: lighten(#000, 50%);

    .title {
        padding-left: 1rem;
    }

    #left__container {
        padding-left: 1rem;

        .select-container {
            .MuiOutlinedInput-root {
                border: none;
            }

            .MuiSelect-nativeInput {
                top: 0;
                opacity: 1;
                padding-left: 12px;
                border: 1px solid gray;
                border-radius: 4.2px;
            }
        }
    }

    .error {
        color: #cf3232;
    }

    #right__container {
        .map__container {
            .field {
                margin: 1rem auto;
                margin-left: 1.3rem;
            }
        }
    }

    .MuiFormLabel-root {
        font-size: 0.9rem;
        font-weight: 500;
        margin-bottom: 0.3rem;
    }

    .MuiOutlinedInput-root {
        border: 0.8px solid $border-color;
        border-radius: 4.2px;
        min-height: 0;
        height: 2.75rem;
        width: 90%;

        &.Mui-focused {
            border: 1px solid #aaa;
        }

        fieldset {
            border: none;
        }
    }

    .MuiTextField-root {
        padding: 0;
        border-radius: 8px;

        & .MuiOutlinedInput-root {
            border: 0.8px solid $border-color;
            border-radius: 4.2px;
            min-height: 0;
            height: 2.75rem;
            width: 90%;

            &.Mui-focused {
                border: 1px solid #aaa;
            }
        }

        .MuiFormLabel-filled {
            font-size: 1.3rem;
        }

        input {
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            color: #272727;
        }

        fieldset {
            border: none;

            legend {
                span {
                    color: #7b7b7b;
                }
            }
        }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    .MuiOutlinedInput-notchedOutline {
        border: 0.8px solid $border-color;

        &:hover,
        &:active {
            border: 1px solid #aaa;
        }
    }

    .category-type-text {
        color: rgb(0 0 0 / 60%);
        font-size: 0.9rem;
        font-weight: 500;
        margin-bottom: 0.3rem;
    }

    .placeholder-text {
        color: rgb(0 0 0 / 60%);
    }

    .category-container {
        .MuiAutocomplete-root {
            margin-bottom: 20px;

            .MuiInputBase-root {
                overflow-y: auto;
                min-height: 53px;
            }
        }

        .MuiInputLabel-root.Mui-focused {
            display: none;
        }

        .MuiInputLabel-root.MuiInputLabel-shrink {
            display: none;
        }

        .MuiInputLabel-root {
            top: -3px;
        }

        .MuiChip-root {
            margin-top: -4px;
        }
    }

    .input-label {
        color: rgb(0 0 0 / 60%);
        font-size: 0.9rem;
        font-weight: 600;
        margin-bottom: 0.3rem;
    }
}
