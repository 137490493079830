#create-project__container {
    background-color: #f9fafc;
    max-width: 100%;
    border-radius: 12px;
    max-height: 94vh;
    min-height: 94vh;
    position: relative;
    overflow-y: auto;

    // max-height: 94vh;

    .inner__container {
        max-width: 100%;
        background: #fff;
        border-radius: 12px;

        .MuiFormHelperText-root {
            margin-left: 0;
        }

        .tab__container {
            .tabs__list {
                background: #6859f9;
                border-radius: 12px 12px 0 0;
                padding: 1rem;

                .tabs {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 0.8rem;
                    color: #fff;
                    margin: 0 1rem;

                    &.active {
                        font-weight: 700;
                        color: #fff500;
                    }
                }

                .divider {
                    min-width: 5%;
                    max-width: 5%;
                    border-color: #fff;
                }
            }

            .project-tabs {
                padding: 1.5rem 2rem;
                max-width: 100%;
                min-width: 100%;

                .title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.5rem;
                    color: #000;
                    margin: 0;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

.reduce-height {
    min-height: 90vh !important;
    max-height: 90vh !important;
}

#create-project__container::-webkit-scrollbar {
    width: 0 !important;
}
