.press-release__container {
    .header__container {
        background-color: rgb(104 89 249 / 100%);
        padding: 5rem;

        .header-label-1 {
            color: white;
            margin-bottom: 2rem;
            font-weight: 400;
            font-size: 1rem;
        }

        .header-title {
            color: white;
            font-weight: 600;
            font-size: 2rem;
            margin-bottom: 2rem;
        }

        .header-label-2 {
            color: white;
            font-weight: 400;
            font-size: 1rem;
        }
    }

    .body__container {
        padding: 3rem;

        .body-title {
            font-weight: 600;
            color: rgb(55 55 55 / 100%);
            font-size: 1rem;
            margin-bottom: 2rem;
        }

        .accordion__container {
            margin-top: 2rem;

            .year-label {
                font-weight: 600;
                color: linear-gradient(0deg, #373737, #373737);
                font-size: 1rem;
            }

            .accordion-content {
                margin-bottom: 2rem;
                color: linear-gradient(0deg, #6f6f6f, #6f6f6f);
            }
        }
    }
}
