.appproved-comments__container {
    .edit__modal {
        .comment-input {
            width: calc(100% - 1rem);
            padding: 0.8rem 1rem;
            background: rgb(104 89 249 / 3%);
            border-radius: 8px;

            input {
                font-weight: 700;
                font-size: 0.9rem;
                color: rgb(107 107 107 / 65%);
            }
        }
    }

    .commented-by-column {
        min-width: 200px;

        .MuiInputBase-root {
            width: 35%;
            height: 35px;
            margin-left: 10px;
        }

        .MuiInputBase-input {
            padding-right: 10px;
            font-size: 12px;
        }
    }

    a {
        word-break: break-all;
    }
}

.edit-delete-timeline__modal {
    .MuiFormControl-root {
        width: 100%;
    }
}
