/* stylelint-disable no-descending-specificity */
@import "https://fonts.googleapis.com/css2?family=Inter&family=Mulish&family=Poppins&display=swap";

.project__container {
    background-color: #eff1f5;
    min-height: 100vh;
    max-width: 100%;
    margin: auto;
    padding-top: 1rem;

    .inner__container {
        max-width: 97%;
        margin: auto;
        padding: 1rem 0;
        background: #eff1f5;

        .tabs-container {
            height: fit-content;
            background-color: #fff;
            border-top-left-radius: 11px;
            border-top-right-radius: 11px;
            border: 1px solid #808080;

            .tab-item {
                height: 3rem;
                width: fit-content;

                p {
                    font-style: normal;
                    cursor: pointer;
                    font-weight: 600;
                    font-size: 0.75rem;
                    color: #4b4b4b;
                }

                margin: 0 0.5rem;

                &.active {
                    color: #6859f9;
                    border-bottom: 2px solid #6859f9;
                }
            }
        }
    }

    .left__container {
        width: 100%;
        background: #eff1f5;

        .project-details__block {
            background: #fff;
            border-bottom-left-radius: 11px;
            border-bottom-right-radius: 11px;
            border: 1px solid #808080;
            border-top: none;
        }

        .header {
            margin: 0;

            img.banner {
                height: auto;
                width: 100%;
                object-fit: fill;
            }
        }

        .left__align-section {
            position: relative;
            top: -3.6rem;
            left: 0.2rem;

            img {
                &.project-picture {
                    width: 11rem;
                    height: 11rem;
                    border: 4px solid #fff;
                    border-radius: 23.11px;
                    margin-left: 3rem;
                }
            }

            .reaction-section {
                margin-top: 1rem;
                margin-left: 1.5rem;

                .reaction-text {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 0.75rem;
                    color: #000;
                }
            }
        }

        .about-section {
            margin: 0 1.25rem;

            .title {
                font-style: normal;
                line-height: 2rem;
                font-weight: 600;
                font-size: 1.2rem;
                margin-top: 2.5rem;
                margin-bottom: 1rem;
                color: #000;
            }

            .objective {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #373737;
                line-height: 1.5rem;
            }
        }

        .status__section {
            padding: 2rem;
            padding-top: 0;

            .status__box {
                // width: 13rem;
                background: rgb(104 89 249 / 3%);
                border: 0.76px solid #ececec;
                border-radius: 9.1px;
                padding: 1rem;
                margin-right: 1rem;
                text-align: center;

                p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 0.88rem;
                    color: #373737;
                }
            }

            .status__field {
                margin-bottom: 15px;

                h2 {
                    font-size: 1.4rem;
                }

                hr {
                    border-color: grey;
                    opacity: 0.5 !important;
                    margin-top: 4px;
                    margin-bottom: 12px;
                }
            }
        }

        .status__btns {
            margin-top: 2.5rem;

            button {
                height: 2.4rem;
                min-width: 6.5rem;
                font-family: Poppins, sans-serif;
                font-weight: 600;
                font-size: 0.8rem;
                line-height: 17px;
                text-transform: capitalize;
                border-radius: 5px;
                letter-spacing: 1px;

                &.outlined-btn {
                    border: 0.8px solid #6859f9;
                    color: #6859f9;
                }
            }
        }
    }

    #timeline__container {
        background: #fff;
        border-radius: 11px;
        padding: 2rem;
        margin-top: 1.5rem;
        max-height: 60vh;
        overflow-y: scroll;
        border: 1px solid #808080;

        &::-webkit-scrollbar {
            display: none;
        }

        margin-bottom: 2rem;

        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            line-height: 24px;
            color: #4b4b4b;
            margin-bottom: 1rem;
        }

        .timeline-section {
            .MuiTimelineDot-root {
                border-color: #6859f9;
                border-width: 4px;
            }

            .MuiTimelineConnector-root {
                background: #e5e5e5 !important;
            }

            .hide__line {
                background: transparent !important;
            }

            .timeline-content {
                padding: 0.75rem 1.3rem;

                .timeline__grid {
                    border-radius: 12px;
                    padding: 1rem 0 1rem 20px;

                    &.light-blue {
                        background: rgb(154 143 255 / 24%);
                    }

                    &.light-pink {
                        background: rgb(255 131 42 / 13%);
                    }

                    .date-grid {
                        margin-right: 0.8rem;

                        .date-text {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 0.9rem;
                            line-height: 27px;
                            white-space: nowrap;
                            color: #000;
                        }
                    }

                    .title {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 0.9rem;
                        color: #373737;
                        margin-bottom: 0;
                    }

                    .content {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 0.9rem;
                        color: #4f4f4f;
                        padding-right: 15px;

                        a {
                            word-break: break-all;
                        }
                    }
                }
            }
        }
    }

    hr {
        background-color: #ece6e6 !important;
        opacity: 1 !important;
    }

    #left-section {
        background: #fff;
        border-radius: 11px;
        padding: 2rem;
        padding-top: 0.6rem;
        border: 1px solid #808080;
        margin-left: 1rem;

        .interest-section {
            padding-right: 0.5rem;
            margin-top: 2rem;
            margin-bottom: 1rem;

            .title {
                padding: 0.5rem 0 0.75rem;
                font-style: normal;
                font-weight: 600;
                font-size: 0.9rem;
                color: #000;
            }

            .label-box {
                &.white-bg .percentage-text {
                    color: white;
                }

                &.black-bg .percentage-text {
                    color: black;
                }

                z-index: 1000;
                position: relative;

                .percentage-text {
                    font-weight: 600;
                    font-size: 0.8rem;
                    color: black;
                    position: absolute;
                    top: 50%;
                    left: 1rem;
                    transform: translateY(-50%);
                }
            }

            .contained-btn {
                margin-top: 1.5rem;
                text-transform: capitalize;
                background: #6859f9;
            }
        }

        #left-header {
            background: #fff;
            border-radius: 10.72px;
            padding-bottom: 1rem;

            .title {
                font-style: normal;
                font-weight: 700;
                font-size: 1rem;
                color: #4b4b4b;
            }
        }

        .right-content {
            margin-right: 0.5rem;

            p {
                font-style: normal;
                font-weight: 600;
                font-size: 0.75rem;
                color: #4b4b4b;
            }

            svg {
                width: 18px;
            }
        }

        .grid__box {
            margin-right: 0.5rem;
            padding: 13.66px 3.41px;
            height: 126.3px;
            background: #fff;
            border: 0.85px solid #e1e1e1;
            border-radius: 5.12px;

            svg {
                margin: 0.4rem auto;
            }

            .title-text {
                font-style: normal;
                font-weight: 600;
                font-size: 0.8rem;
                text-align: center;
                line-height: 20px;
                color: #373737;
            }

            .content-text {
                font-style: normal;
                font-weight: 500;
                font-size: 0.65rem;
                text-align: center;
                color: #000;
            }
        }

        .participant-type__container {
            margin-top: 15px;
            padding: 0 0.5rem;

            .heading {
                font-weight: bold;
            }

            .item {
                background-color: #f1f1f1;
                border-radius: 15px;
                padding: 4px 5px;

                .MuiTypography-root {
                    font-weight: bold;
                }
            }
        }

        .info-icon {
            background-color: #d9d9d9;
            border-radius: 50%;
            padding: 0 6px 3px;
            font-weight: bold;
            font-size: 14px;
        }
    }

    #left__second-section {
        background: #fff;
        border-radius: 11px;
        border: 1px solid #808080;
        margin-left: 1rem;

        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            color: #4b4b4b;
            padding: 2rem;
            padding-bottom: 1.5rem;
        }

        .insight-login-btn {
            background: #6859f9;
            text-transform: none;
            width: 12rem;
            margin: auto;
            margin-left: 50%;
            transform: translate(-50%, -1rem);
            border-radius: 4rem;
        }

        .insight-container {
            max-height: 30vh;
            overflow-y: scroll;

            &.blur-bg {
                filter: blur(2px); /* Safari */ /* IE 10 and IE 11 */
                user-select: none; /* Standard syntax */
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .divider {
            width: 100%;
        }

        .card__row {
            padding: 1rem 2rem;

            .card-icon {
                background: #6859f9;
                height: 54px;
                width: 54px;
                margin-right: 1rem;
                border-radius: 5px;
            }

            .content__section {
                .header__title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1rem;
                    color: #000;
                    margin-bottom: 0.78rem;
                    word-break: break-all;
                }

                .content {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.9rem;
                    color: #4f4f4f;
                }
            }
        }
    }

    #left__third-section {
        background: #fff;
        padding: 2rem;
        margin-left: 1rem;
        border-radius: 11px;
        border: 1px solid #808080;
    }
}

.MuiTooltip-tooltipPlacementBottom {
    background-color: #bcd6dd;
    text-align: center;
    color: black;
}
