.comments-tab {
    padding: 1rem 2rem;
    background: #fff;
    border: 1px solid #808080;
    border-top: none;
    border-bottom-left-radius: 11px;
    border-bottom-right-radius: 11px;

    .comment-container {
        max-height: 60vh;
    }

    .comments-container {
        max-height: 38vh;
        overflow-y: scroll;

        &.blur-bg {
            filter: blur(4px); /* Safari */ /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .comment-item {
            height: fit-content;
            background: rgb(104 89 249 / 3%);
            padding: 1rem;
            margin: 1rem 0;

            .comment-avatar {
                width: 3.5rem;
                height: 3.5rem;
                box-shadow: 3.51px 3.51px 48.32px rgb(0 0 0 / 10%);
                padding: 0;

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    object-fit: fill;
                }
            }

            .comment-text {
                font-style: normal;
                font-size: 0.9rem;
                color: #414141;
                margin-top: 0.5rem;
            }

            .sub-text {
                font-weight: 700;

                .added-by {
                    font-style: italic;
                    text-decoration: underline;
                    cursor: pointer;
                    margin-right: 1rem;
                }
            }

            .chip-expert {
                height: 16px;

                // background-color: pink;
            }

            .add-time {
                margin-left: 25px;
            }

            .reply-btn {
                background: transparent;
                border: 1px solid #6859f9;
                color: #414141;
                margin-top: 5px;

                img {
                    margin-right: 5px;
                }
            }
        }
    }

    .comment-input__container {
        margin: 1rem 0;
        width: 100%;

        &.blur-bg {
            filter: blur(4px);
        }

        .comment-input {
            width: calc(100% - 1rem);
            padding: 0.8rem 1rem;
            background: rgb(104 89 249 / 3%);
            border-radius: 8px;

            input {
                font-weight: 700;
                font-size: 0.9rem;
                color: rgb(107 107 107 / 65%);
            }
        }

        // .comment-input__highlighter {
        //     display: none;
        // }
        .mention-text {
            color: #6859f9;
        }

        .comment-btn {
            height: 50px;
            width: 100%;
            background: #6859f9;
            border-radius: 4.68px;
            font-weight: 800;
            text-transform: capitalize;
            font-size: 0.7rem;
            line-height: 100%;
            color: #fff;
        }
    }

    .btn {
        height: 50px;
        width: 100%;
        background: #6859f9;
        border-radius: 4.68px;
        font-weight: 800;
        text-transform: capitalize;
        font-size: 0.7rem;
        line-height: 100%;
        color: #fff;

        &.raise-up {
            transform: translate(-50%, -64px);
            margin-left: 50%;
            width: 18rem;
            border-radius: 20.68px;
            background: #5364ff;
        }

        &:hover,
        &:active {
            background-color: #6859f9;
            color: #fff;
        }
    }

    .no-comments {
        text-align: center;
        font-size: 1rem;
        font-weight: 700;
        width: 100%;
        color: #414141;
        margin: 1rem 0;
    }
}
