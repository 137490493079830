#sidebar-container {
    margin-top: 2rem;
    border-right: 2px solid #f5f9ff;

    .link {
        width: 80%;
        background-color: #fff;
        padding: 0.6rem 1rem;
        text-decoration: none;
        color: #595959;
        text-align: left;
        margin: 0.6rem 0.1rem;
        font-style: normal;
        text-transform: capitalize;

        .nav__title {
            font-weight: 600;
            font-size: 0.875rem;
        }

        &.active {
            background: #6859f9;
            border-radius: 6px;
            color: #fff;
        }
    }
}
