.item_container {
    background: #ffffff;
    border-radius: 5.57824px;
    //margin: 0 0.5rem !important;
    border: 1px solid #f3f4f6;

    .item-header__container {
        position: relative;
        border-radius: 5.57824px 5.57824px 0px 0px;
        height: 80px;
        padding: 1rem;
        &.blue {
            background: #4154e8;
        }
        &.orange {
            background: #ff832a;
        }
        &.red {
            background: #e8414b;
        }
        &.green {
            background: #00c7af;
        }
        &.green3 {
            background: #0083cd;
        }
        &.blue2 {
            background: #7a89ff;
        }
        .item-title {
            font-family: Lato;
            font-weight: 500;
            font-size: 0.8rem;
            line-height: 20px;
            letter-spacing: 0.03em;
            color: #ffffff;
        }
        .item-click {
            font-family: Lato;
            font-weight: 400;
            font-size: 0.68rem;
            line-height: 17px;
            margin-top: 0.5rem;
            letter-spacing: 0.03em;
            cursor: pointer;
            color: #ffffff;
            &:hover {
                text-decoration: underline;
            }
        }
        .user-icon {
            position: absolute;
            right: 1rem;
            width: 2.8rem;
            height: 2.8rem;
        }
        .more-icon {
            width: 1rem;
            height: 1rem;
            cursor: pointer;
        }
    }
    .item-value__container {
        height: 4.5rem;
        padding: 1rem;
        .item-value {
            font-family: Lato;
            font-weight: 700;
            font-size: 1rem;
            line-height: 24px;
            letter-spacing: 0.929707px;
            text-transform: capitalize;

            color: #000000;
        }
    }
}
