.social_partners_container {
    .social_partners_header {
        padding: 3rem 0;
        background: linear-gradient(180deg, #6859f9 0%, #5986f9 100%);
        color: white;
        > p,
        > h1 {
            line-height: 40px;
        }
        h1 {
            margin-top: 1.2rem;
            margin-bottom: 2rem;
            font-size: 2.5rem;
        }
    }
    .social_partners_cards_wrapper {
        padding: 2rem;
        margin: 2rem 0 5rem 0;
    }
    .social_partners_card_item {
        height: fit-content;
        max-width: 400px;
    }
}
