.create-user__container {
    padding: 10px 0 0 20px;

    .create-user {
    
        background: #fff;
        box-shadow: 4px 4px 70px rgb(0 0 0 / 7%);
        padding: 50px 40px 70px;
    }

    .input-container {
        width: 48%;

        input {
            padding: 10px;
            font-family: Poppins;
            font-weight: 400;
            font-size: 0.7rem;
            line-height: 15px;
            color: #858585;
        }
    }

    label {
        display: inline-block;
        width: 100%;
        color: rgb(0 0 0 / 60%);
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 12px;
    }

    .input {
        width: 100%;
    }

    .create-btn {
        width: 100%;
        background: #6859f9;
        border-radius: 5px;
        padding: 10px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.5px;
        display: inline-block;
        border: none;
    }

    .usertype__container {
        .MuiSelect-outlined {
            padding: 8px;
            font-size: 0.7rem;
        }

        .MuiFormLabel-root.MuiFormLabel-filled {
            display: none;
        }

        input {
            opacity: 1;
        }
    }
}
