
.pending-exp__container {
    width: 100%;
    > div {
        width: 100%;
    }
    .exp-table {
        th {
            white-space: nowrap;
        }

        tr :nth-child(6) {
            white-space: nowrap;
        }
        tr :nth-child(7) {
            min-width: 140px;
        }
    }
}

