.register-modal-content__container {
    margin-top: 0.5rem;

    .user-register-form {
        margin: 0.5rem;

        .register-modal-select-input .MuiSelect-select {
            padding-top: 16.5px;
            padding-bottom: 16.5px;
        }

        .select-helper-text {
            padding-left: 14px;
        }

        .multiple-select-wrapper {
            width: 100%;

            .MuiInputBase-root {
                width: 100%;
            }
        }

        form {
            width: 100%;
        }

        input {
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            color: #858585;
        }

        // button {
        //     width: 100%;
        //     text-transform: capitalize;
        //     height: 3.5rem;

        //     img {
        //         width: 1.5rem;
        //     }

        //     &.signup__btn {
        //         font-style: normal;
        //         font-weight: 500;
        //         font-size: 1rem;
        //         color: #FFFFFF;
        //         background: #6859F9;
        //         border-radius: 6px;
        //     }

        //     &.google-auth__btn {
        //         border: 1px solid #BDBDBD;
        //         border-radius: 6px;
        //         font-family: 'Mulish';
        //         font-style: normal;
        //         font-weight: 400;
        //         font-size: 14px;
        //         color: #2F2F2F;
        //     }
        // }

        .or__divider {
            margin: 0.75rem 0;

            .MuiDivider-wrapper {
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
                color: #4e4e4e;
                background: white;
                padding: 0 0.5rem;
            }
        }

        .MuiDivider-root::before,
        .MuiDivider-root::after {
            border-top: 1px solid #c0c0c0;
        }
    }

    .field__grid {
        padding: 1rem 1rem 1rem 0;
    }

    .input__label {
        margin-bottom: 0.25rem;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: rgb(0 0 0 / 60%);
    }

    .MuiOutlinedInput-input {
        height: 1rem !important;
    }

    .input__field {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #858585;
        width: 85%;
        margin-bottom: 10px;
    }

    .continue__btn {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: #fff;
        background: #6859f9;
        border-radius: 4px;
    }

    /* .common-textfield {
        padding-left: 0;
        padding-right: 0;
        border: none;
        height: 2.4rem;
        width: 15rem;
        font-size: 0.9rem;
        padding: 0.375rem 0.75rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;

        .MuiInputAdornment-positionEnd,
        .MuiInputAdornment-positionStart {
            height: 150%;
            padding: 0.2rem 0.75rem;
            margin-bottom: 0;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            text-align: center;
            white-space: nowrap;
            background-color: #e9ecef;
        }

        .MuiInputAdornment-positionEnd {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-left: 1px solid black;
            margin-right: -0.8rem;
        }

        .MuiInputAdornment-positionStart {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            border-right: 1px solid black;
            margin-left: -0.8rem;
        }

        input {
            padding: 0.7rem;
            font-size: 0.9rem;
            padding-left: 0;

            &::placeholder {
                font-size: 0.8rem;
            }
        }
    } */
    .title {
        color: #1c4eff;
    }

    .title-label {
        color: #6c6969;
        font-size: 12px;
        margin: 0 0 3px;
    }

    .terms__text {
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
        text-align: center;
        color: #7a7a7a;
        width: 100%;
        margin: 0.8rem auto;

        a {
            font-weight: 600;
            font-size: 0.85rem;
            color: #1154ff;
            text-decoration: none;
        }
    }

    .checkbox__container {
        margin: 20px 0 10px 20px;

        p {
            margin: 0;
            font-size: 14px;

            .MuiButtonBase-root {
                padding: 5px;
            }
        }
    }
}

.terms__text {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    text-align: center;
    color: #7a7a7a;
    width: 100%;
    margin: 60px 0 10px;

    a {
        font-weight: 600;
        font-size: 0.85rem;
        color: #1154ff;
        text-decoration: none;
    }
}

.common-modal__container {
    padding: 4rem 1.5rem 1.8rem;

    .span-text {
        color: #1154ff;
    }

    .modal-select-input {
        width: 100%;
    }

    .MuiSelect-select {
        border: none;
    }

    .csr-select-input {
        width: 45%;
        margin-top: 10px;
    }

    .usertype__container {
        position: relative;

        .MuiFormLabel-root {
            top: -6px;
            color: black;
            font-size: 1.13rem;
        }

        .MuiInputLabel-root.MuiInputLabel-shrink {
            display: none;
        }

        .MuiSvgIcon-root {
            color: #1154ff;
            font-size: 2rem;
        }

        .others-textfield__container {
            margin: 0;

            span {
                background-color: white;
                position: absolute;
                left: 1px;
                top: 1px;
                bottom: 1px;
                width: 14%;
                padding: 8px;
                border: 1px solid #aaa;
                border-radius: 4px 0 0 4px;
            }

            input {
                position: absolute;
                z-index: 10;
                width: 78%;
                top: 1px;
                bottom: 1px;
                left: 14%;
                padding: 7px;
                border: 1px solid #aaa;
            }

            input:focus {
                outline: none;
            }
        }
    }

    .input__field {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #858585;
        width: 45%;
    }

    #radio-btns-label {
        margin: 0;
    }

    .radio__container {
        margin-top: 10px;
        padding-top: 5px;
    }

    .checkbox__container {
        margin: 20px 0 10px;

        p {
            margin: 0;
            font-size: 12px;

            .MuiButtonBase-root {
                padding: 5px;
            }
        }
    }

    .continue__btn {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: #fff;
        background: #6859f9;
        border-radius: 4px;
        text-transform: none;
    }

    .button__container {
        padding-top: 15px;
    }

    .back__btn {
        width: 100px;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: black;
        background: #d9d9d9;
        border-radius: 4px;
        text-transform: none;
    }
}
