.kyc__file-name {
    width: 10.3rem;
    height: 2.62rem;
    background: #6859f9;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 12px;
    color: #fff;
    border: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #6959f9ee;
        color: white;
    }
}

.exp-table {
    th {
        font-family: Poppins, sans-serif;
        font-weight: 600;
        font-size: 0.8rem;
        text-align: center;
        line-height: 18px;
        color: #565656;
    }

    td {
        font-family: Poppins, sans-serif;
        font-weight: normal;
        font-size: 0.8rem;
        line-height: 18px;
        text-align: center;
        color: #818181;
    }
}
