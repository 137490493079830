.nav-btns__container {
    position: sticky;
    bottom: 0;
    width: 100vw;
}

.apply-min-height {
    min-height: 94vh;
}

.nav__btns {
    width: 100vw;
    position: sticky;
    bottom: 0;
    background-color: #f6f6f6;
    padding: 20px 0;
    border-radius: 6px;

    img {
        margin-right: 8px;
        width: 18px;
    }

    button {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 0.7rem;
        color: #fff;
        padding: 0.3rem 2rem;
        min-height: 0 !important;
        margin-left: 12px;

        &.contained-btn {
            background: #6859f9;
            border-radius: 5px;
        }

        &.outlined-btn {
            border: 0.8px solid #6859f9;
            color: #6859f9;
            border-radius: 5px;
        }
    }
}

.modal-title {
    font-weight: 600;
    font-size: 1.1rem;
    color: #373737;
    margin-bottom: 0.5rem;

    span {
        font-size: 1rem;
    }
}

.modal-btn__container {
    margin-top: 1rem;

    .modal-btn {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 0.7rem;
        color: #fff;
        padding: 0.3rem 2rem;
        min-height: 0 !important;
        background: #6859f9;
        border-radius: 5px;
        margin-left: 1rem;
        min-width: 6rem;

        &.outlined {
            background: #fff;
            color: #6859f9;
            border: 0.8px solid #6859f9;
        }
    }
}
