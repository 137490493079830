.login-page__container {
    min-height: 100vh;
    position:relative;

    .login-panel {
        width: 40%;
        background: #FFFFFF;
        box-shadow: 4px 4px 80px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        z-index: 2;
        padding:60px 80px 70px;
    }

    @media only screen and (max-width: 1100px) {
        .login-panel {
            width:50%;
            padding:40px 60px 50px;
        }
    }

    @media only screen and (max-width: 900px) {
        .login-panel {
            width:65%;
        }
    }

    @media only screen and (max-width: 700px) {
        .login-panel {
            width: 85%;
        }
    }

    @media only screen and (max-width: 500px) {
        .loginpanel {
            width: 95%;
        }
    }

    .heading {
        text-align: center;
        font-size: 1.5rem;
        color: black;
        font-weight: 600;
        margin-bottom: 30px;
    }

    .input__label {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 600;
        display:inline-block;
        width:100%;
    }

    .input {
        margin-bottom: 25px;
        width: 100%;

        input {
            padding: 10px;
        }
    }
    .err__label{
        font-size:12px;
        color:red;
    }
    .forget-password__label {
        font-size: 12px;
        cursor: pointer;
        color: #333;
        text-decoration: underline;
    }
    .login-btn {
        padding:10px;
        background-color: #6859F9;
        border-radius: 5px;
        border:none;
        color:white;
        width:100%;
    }

    .bottom-img {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }

}