.pending-comments__container {
    .commented-by-column {
        .MuiInputBase-root {
            width: 35%;
            height: 35px;
            margin-left: 10px;
        }

        .MuiInputBase-input {
            padding-right: 10px;
            font-size: 12px;
        }
    }

    a {
        word-break: break-all;
    }
}
