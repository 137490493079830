#detailed-information__container {
    $border-color: lighten(#000, 50%);

    .title {
        padding-left: 1rem;
    }

    #left__container {
        padding-left: 1rem;
    }

    .error {
        color: #cf3232;
        margin-left: 0;
    }

    #right__container {
        .field {
            // max-width:90%;
            margin: 1rem auto;
            margin-left: 1.3rem;
        }

        .add-icon {
            cursor: pointer !important;
            width: 1.5rem;
            height: auto;
            color: #6859f9;
        }

        .question-answer__list {
            .question-answer__item {
                margin: 0.5rem 0;
                border: 0.8px solid $border-color;
                border-radius: 3px;
                padding: 0.5rem;

                .question {
                    font-weight: 500;
                    font-size: 1rem;
                    color: #373737;
                }

                .answer {
                    font-weight: 400;
                    font-size: 0.8rem;
                    letter-spacing: 0.3px;
                    color: #373737;
                }
            }
        }
    }

    .MuiFormLabel-root {
        font-size: 0.9rem;
        font-weight: 500;
        margin-bottom: 0.3rem;
    }

    .count {
        font-size: 0.85rem;
        font-weight: 500;
        margin-bottom: 0.3rem;
        color: #7b7b7b;
        margin-left: 6px;
    }

    .field {
        .MuiTextField-root {
            padding: 0;
            border-radius: 8px;

            & .MuiOutlinedInput-root {
                border: 0.8px solid $border-color;
                border-radius: 4px;
                min-height: 0;
                height: 2.75rem;

                &.Mui-focused {
                    border: 1px solid #aaa;
                }
            }

            .MuiFormLabel-filled {
                font-size: 1.3rem;
            }

            input {
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                color: #272727;
            }

            fieldset {
                border: none;

                legend {
                    span {
                        color: #7b7b7b;
                    }
                }
            }
        }
    }

    .MuiTextField-root {
        input {
            font-size: 1rem;
        }
    }

    .textarea-field {
        &.ml {
            margin-left: 0.3rem;

            .MuiTextField-root {
                margin: auto;
            }
        }

        textarea {
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: 0.3px;
            color: #373737;
        }

        .rdw-editor-wrapper {
            border: 1px solid grey;
            border-radius: 5px;
            margin-bottom: 20px;
            width: 98%;
            min-height: 165px;
            max-height: 165px;
            max-width: 423px;
            overflow-y: scroll;
            padding: 4px;

            .DraftEditor-root {
                padding: 0 6px;
            }
        }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    .MuiSelect-select {
        min-height: 0;
        padding: 0.5rem;
        border-radius: 4px;

        &:hover,
        &:active {
            border: 1px solid #aaa;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border: 0.8px solid $border-color;

        &:hover,
        &:active {
            border: 1px solid #aaa;
        }
    }
}
