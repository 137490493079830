#profile__container {
    // margin-top: 7rem;
    // background-color: white;
    // margin: 3rem;
    // margin-top: 1rem;
    // padding-top: 2rem;

    // width: calc(100% - 6rem);
    // border-radius: 1rem;
    // box-shadow: 0 0 0.2rem 0 rgb(0 0 0 / 20%);

    .request_verification_container {
        padding-top: 20px;

        label {
            cursor: pointer;
            margin-bottom: 10px;
        }

        a {
            text-decoration: none;
        }

        hr {
            border-bottom-width: 1.5px;
            border-color: black;
        }
    }

    .sidebar__container {
        width: 20%;
        padding: 20px;
        margin-top: 15px;
        min-height: 50vh;
        max-height: 50vh;
        border-right: 1px solid rgb(0 0 0 / 42%);

        p {
            text-align: center;
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
            margin-bottom: 30px;
        }

        .sidebar-link {
            color: #0b0b0b;
            border-radius: 50px;
            padding: 10px 25px;
            text-decoration: none;
            width: 90%;
            display: flex;
            justify-content: space-between;
            margin: 10px auto;
        }

        .selected {
            background-color: #6859f9;
            color: white;
        }

        @media only screen and (max-width: 900px) {
            min-height: 0;
        }
    }

    .main-profile__container {
        width: 80%;
    }

    .title {
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
        color: #414141;
    }

    .btn-container {
        margin: 1rem 0;
        padding: 10px 42px 0;

        @media only screen and (max-width: 768px) {
            padding: 0;
        }

        .btn {
            font-size: 17px;
            padding: 10px 30px;
            text-transform: capitalize;
            color: black;
            margin: 0 0.5rem;
            background-color: #d9d9d9;

            &.filled {
                color: #fff;
                background-color: #6859f9;
            }

            @media only screen and (max-width: 768px) {
                font-size: 14px;
                padding: 8px 20px;
            }
        }
    }

    .camera__badge {
        .MuiBadge-badge {
            left: 41.3%;
            top: 60%;
            cursor: pointer;
        }
    }

    .badge__section {
        background: white;
        border-radius: 50%;
        padding: 0.4rem;
        box-shadow: 0 0.1rem 0.1rem #00000054;

        .camera__icon {
            font-size: 1.1rem;
        }
    }

    .main-profile {
        // min-height: 50vh;
        // background-color: white;
        // padding: 1rem 2rem;
        // margin-bottom: 2rem;
        padding: 30px 40px;

        @media only screen and (max-width: 768px) {
            padding: 20px;
        }

        // width: 90%;
        // margin: 0 auto;

        .tab {
            border-radius: 6px;
            font-weight: 600;
            font-size: 0.8rem;
            text-align: center;
            padding: 0.5rem 1rem;
            margin-right: 0.5rem;
            cursor: pointer;
        }

        .active {
            background: #6859f9;
            color: white;
        }

        .MuiAvatar-root {
            width: 4.5rem;
            height: 4.5rem;
            cursor: pointer;
            margin-right: 1rem;
            box-shadow: 0 0 0.2rem #bbbbbbab;
        }

        h6 {
            font-weight: 600;
            font-size: 1.3rem;
            color: #414141;
        }

        p {
            font-weight: 400;
            font-size: 1rem;
            color: #414141;
        }

        h4 {
            font-weight: 600;
            font-size: 1rem;
            color: #414141;
        }

        .input-container {
            margin-bottom: 30px;
            padding-left: 20px;

            @media only screen and (max-width: 900px) {
                padding-left: 0px;
                font-size: 14px;
            }

            .field {
                padding: 0.5rem 1.5rem;
            }

            .verified {
                position: absolute;
                right: 50%;
                top: 30px;
            }
        }

        .kyc-container {
            padding: 0 50px;

            @media only screen and (max-width: 900px) {
                padding: 0 20px;
            }

            .field {
                padding: 0.5rem 1.5rem;
            }
        }

        .heading {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            padding: 0 2.2rem;

            @media only screen and (max-width: 768px) {
                padding: 6px 1.5rem;
            }
        }

        .kyc-heading {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
        }

        .sub-heading {
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
        }

        .drag-and-drop__container {
            min-height: 300px;
            background-color: rgb(217 217 217 / 43%);
            border: 1px dashed rgb(59 58 58 / 50%);
            margin-bottom: 20px;

            .upload-btn {
                background: #fefefe;
                border: 1px solid rgb(0 0 0 / 20%);
                border-radius: 5px;
                padding: 6px 12px;
                min-width: 150px;
                display: flex;
                align-items: center;

                img {
                    margin-right: 10px;
                }
            }
        }

        .textarea {
            width: 100%;
            padding: 20px;
        }

        .uploaded-items {
            background: rgb(217 217 217 / 43%);
            border-radius: 5px;
            margin-bottom: 15px;
            padding: 10px 20px;

            .icon {
                margin-right: 20px;
            }
        }
    }

    .MuiDrawer-root {
        flex-shrink: 0;
        z-index: 10;

        .MuiDrawer-paper {
            margin-top: 10rem;
            box-sizing: border-box;
            position: absolute;
            border: none;
        }
    }
}

.modal-header {
    background: #6859f9;
}

.pac-container {
    z-index: 1000000 !important;
}

.react-pdf__Document {
    max-height: 80vh;
    overflow-y: scroll;

    .react-pdf__Page__annotations {
        display: none;
    }
}
