.navbar-container {
    padding: 0.5rem 2rem 0.5rem 2rem;
    background: white;

    &.dark-mode {
        background: #6859f9;

        * {
            color: white !important;
        }
    }

    .brand-logo {
        width: 2rem;
        margin-right: 3rem;
    }

    .nav-item-wrapper {
        height: 100%;
        width: fit-content;
        padding: 0.5rem 0;

        &.active {
            border-bottom: 2px solid #6859f9;
        }
    }

    .nav-item {
        font-weight: 600;
        font-size: 1rem;
        color: var(--link-color);
        text-decoration: none;
        margin: 0 0.5rem;
        margin-left: 1rem;
    }

    a,
    a:hover,
    a:focus {
        text-decoration: none;
        border: none;
    }

    .username {
        font-weight: 500;
        font-size: 1rem;
        color: var(--link-color);
    }

    .MuiAvatar-root {
        margin: 0 0.5rem;
        cursor: pointer;
    }

    .down-arrow {
        width: 1rem;
        height: auto;
        color: var(--link-color);
        cursor: pointer;
    }

    .nav-icon {
        cursor: pointer;
        margin: 0 0.5rem;
        width: 1.3rem;
        height: auto;
        color: var(--link-color) !important;

        &.notification-icon {
            width: 1.5rem;
        }
    }

    svg {
        cursor: pointer;
    }

    .MuiTypography-body1 {
        font-family: "Roboto", "Helvetica", "Arial", sans- !important;
    }
}

.options-popper {
    .popper-item {
        font-weight: 500;
        font-size: 0.8rem;
        color: #333;
        text-decoration: none;
        padding: 0.5rem;
        cursor: pointer;
        &:hover {
            background-color: rgb(221, 221, 221);
        }

        .popper-icon {
            width: 1.2rem;
            height: auto;
            color: #6859f9;
            margin-right: 0.2rem;
        }
    }
}
