.share__modal {
    .header {
        padding: 15px 15px 5px;

        span {
            margin-right: 8px;
        }
    }

    .footer {
        padding: 15px;

        span {
            color: #6859f9;
        }

        .link-icon {
            transform: rotate(-50deg);
        }

        .MuiSvgIcon-root {
            margin-right: 2px;
            color: #6859f9;
        }
    }

    .mid-section {
        padding: 15px;

        .MuiOutlinedInput-input {
            padding: 11.5px 14px;
        }
    }

    .share-input-field {
        width: 75%;
    }

    .btn {
        background-color: #6859f9;
        color: white;
        width: 23%;
        border-radius: 10px;
    }

    .MuiDivider-root {
        width: 100%;
        border-color: rgb(0 0 0 / 60%);
    }
}
