.all-projects__container {
    .tabs__container {
        .MuiButtonBase-root.Mui-selected {
            background-color: #6859f9;
            color: white;
            border: none;
        }

        button {
            padding: 10px 35px;
            font-weight: 700;
            font-size: 13px;
            line-height: 18px;
            border: 1px solid #898989;
        }

        button:first-child {
            border-radius: 6px 0 0 6px;
        }

        button:last-child {
            border-radius: 0 6px 6px 0;
        }

        span {
            display: none;
        }
    }
}
