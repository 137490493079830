.FaqsContent {
    .container {
        background: #fff;
        padding: 2rem 0 0.5rem 0;
        .header {
            h1 {
                font-size: 1.6rem;
                font-weight: 300;
            }
        }
    }
    .faq_accordion_wrapper {
        margin: 2.5rem 0;
    }
    .faq_accordion_item {
        background: #ffffff;
        box-shadow: 0px 13.3113px 19.967px 3.32784px rgba(90, 82, 128, 0.12);
        margin-bottom: 2rem;
    }
    .faq_accordion_header {
        padding: 2rem 1rem;
        font-weight: 600;
    }
    .faq_accordion_body {
        color: #858585;
    }
}
