.gallery_container {
    .gallery_header {
        padding: 3rem 0;
        background: linear-gradient(180deg, #6859f9 0%, #5986f9 100%);
        color: white;
        > p,
        > h1 {
            line-height: 40px;
        }
        h1 {
            margin-top: 1.2rem;
            margin-bottom: 2rem;
            font-size: 2.5rem;
        }
    }
    .image_gallery_container {
        padding: 5rem;
        img {
            border-radius: 25px;
        }
    }
    .gallery_video_player {
        height: 100%;
        width: 100%;
    }
}
