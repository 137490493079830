@import "https://fonts.googleapis.com/css2?family=Poppins&display=swap";

.home-admin__container {
    // background-color: #fff;
    .sidebar__container {
        width: 15%;
        height: 100vh;
    }

    .main_container {
        width: 78%;
        background: rgb(255 255 255);
        padding: 20px 20px 0;

        //height: 90vh;
        overflow-y: visible;
        margin-top: 1.5rem;

        // box-shadow: 4px 4px 70px rgb(0 0 0 / 7%);
        box-shadow: inset 6px 2px 60px rgb(227 227 227 / 9%);

        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 1.25rem;
            color: #000;
        }

        p {
            &.MuiTablePagination-selectLabel,
            &.MuiTablePagination-displayedRows {
                margin: 0 !important;
            }
        }

        .MuiTableContainer-root {
            width: 100%;
            overflow-x: hidden;
        }

        .rm-td__padding {
            padding: 0;
        }

        .action__btns {
            button {
                border: none;
                background-color: white;
                padding: 5px;
                display: inline;
                margin: 0;
            }

            .action__icon {
                width: 35px;
                height: 35px;
            }
        }

        .table-header-font-style tr th {
            font-size: 0.8rem;
            font-weight: 600;
            white-space: nowrap;
        }

        .tabs__container {
            .MuiButtonBase-root.Mui-selected {
                background-color: #6859f9;
                color: white;
                border: none;
            }

            button {
                padding: 10px 35px;
                border: 1px solid #898989;
                font-family: Poppins;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                color: #898989;
            }

            button:first-child {
                border-radius: 6px 0 0 6px;
            }

            button:last-child {
                border-radius: 0 6px 6px 0;
            }

            span {
                display: none;
            }
        }

        /*  &::-webkit-scrollbar {
            width: 12px;
        } */
    }

    .main_container::-webkit-scrollbar {
        display: none;
    }
}
