.ResetPasswordVerification {
    min-height: calc(100vh - 56px);
    background-color: #f9fafc;
    display: flex;
    justify-content: center;
    align-items: center;

    main.reset-card {
        background: #fff;
        border-radius: 0.4rem;
        flex-basis: min(100%, 450px);
        overflow: hidden;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        @media screen and (max-width: 450px) {
            min-height: 100vh;
            border-radius: 0;
        }

        .header {
            background: #6859f9;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 1rem 0;
            h1 {
                color: #fff;
                font-size: 1.5rem;
                line-height: 1;
            }
        }

        form.body {
            .body-row {
                padding: 1rem 2rem;

                label {
                    display: flex;
                    flex-direction: column;

                    .label {
                        font-size: 0.8rem;
                    }

                    input {
                        outline: none;
                        padding: 0.2rem;
                    }
                }
                small {
                    font-size: 0.7rem;
                }
            }

            hr {
                margin: 0.2rem 0;
            }

            .submit-section {
                padding: 1rem 2rem;
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                    background: #6859f9;
                    border: none;
                    border-radius: 0.2rem;
                    color: #fff;
                    outline: none;
                    padding: 0.2rem 1rem;
                    font-size: 0.9rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media screen and (max-width: 450px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}
