#main-layout__container {
    width: 100%;

    .footer__container {
        margin-top: 1rem;
    }
}

#main-layout__content {
    width: 100%;
    height: 100%;
}
