/* stylelint-disable no-descending-specificity */
#media-timeline__container {
    .title {
        padding-left: 1rem;
    }

    .error {
        color: #fffb00;
    }

    #left__container {
        .upload-card {
            background: #6859f9;
            box-shadow: 0 24px 50px 4px rgb(90 82 128 / 24%);
            border-radius: 26px;
            padding: 1rem 1.5rem;
            margin: 1rem 0;

            .add-title {
                font-weight: 700;
                font-size: 1rem;
                color: #fff;
            }

            .add-subtitle {
                font-weight: 400;
                font-size: 0.8rem;
                letter-spacing: 0.3px;
                color: #fff;
            }

            .upload-btn {
                background: #fff;
                border-radius: 8px;
                text-transform: capitalize;
                font-weight: 700;
                font-size: 0.6rem;
                color: #6859f9;
                width: 100%;
                margin: 1rem 0;
            }

            .file-name {
                font-weight: 400;
                font-size: 0.8rem;
                letter-spacing: 0.3px;
                text-align: center;
                width: 100%;
                color: #fff;
            }
        }
    }

    #right__container {
        position: relative;

        .date-container {
            background: #fff;
            box-shadow: 4px 4px 44px rgb(0 0 0 / 4%);
            border-radius: 12px;
            margin-bottom: 0.5rem;
            padding: 1rem;

            &.blue {
                background: rgb(154 143 255 / 24%);
            }

            &.pink {
                background: rgb(255 131 42 / 13%);
            }

            .rdp-months {
                position: absolute !important;
            }

            .date-info {
                font-weight: 600;
                font-size: 1rem;
                color: #000;

                .calender-icon {
                    width: 1.3rem;
                    cursor: pointer;
                    height: auto;
                    margin-right: 0.5rem;
                }

                .MuiOutlinedInput-root {
                    padding: 0;
                    border: none;

                    input {
                        width: 0;
                        border: none;
                        padding: 0;
                    }
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }

            .add-date__container {
                padding: 0 2rem;

                .date-input {
                    margin: 0.1rem 0;

                    input {
                        font-weight: 400;
                        font-size: 0.85rem;
                        color: #373737;
                    }
                }

                .project-title {
                    font-weight: 600;
                    font-size: 1rem;
                    color: #373737;
                    margin-bottom: 0.5rem;
                }

                .edit-icon {
                    width: 1.5rem;
                    height: auto;
                    cursor: pointer;
                    color: #6859f9;
                }

                .delete-icon {
                    width: 1.5rem;
                    height: auto;
                    cursor: pointer;
                    color: #dc2855;
                    margin-left: 0.5rem;
                }

                .project-subtitle {
                    font-weight: 400;
                    font-size: 0.8rem;
                    color: #4f4f4f;
                    padding-right: 18px;

                    a {
                        word-break: break-all;
                    }
                }
            }

            .date-add__icon {
                width: 1.5rem;
                height: auto;
                cursor: pointer;
                color: #6859f9;
            }
        }
    }
}

.modal-input__label {
    font-weight: 600;
    font-size: 1rem;
    color: #373737;
    margin-bottom: 0.5rem;
}

.modal-input__field {
    padding: 0.5rem 1rem;
    border: 1px solid #4f4f4f;
    margin: 0.5rem 0;

    input {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        color: #272727;
    }
}

.modal-upload__btn {
    color: #fff;
    border-radius: 8px;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 0.8rem;
    background: #6859f9;
    width: 100%;
    padding: 0.8rem;
    margin: 1rem 0;

    &:hover {
        background: #6859f9;
    }
}

.rdp-months {
    padding: 0.5rem;
    background-color: #fff;
    border-radius: 5px;
}

.edit-delete-timeline__modal {
    .date-info {
        .calender-icon {
            width: 1.3rem;
            cursor: pointer;
            height: auto;
            margin-right: 0.5rem;
        }

        .MuiOutlinedInput-root {
            padding: 0;
            border: none;

            input {
                width: 0;
                border: none;
                padding: 0;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border: none;
        }
    }

    .label {
        font-family: Poppins, sans-serif;
        font-weight: 600;
        font-size: 0.85rem;
        margin: 0.5rem 0;
        line-height: 16px;
        color: rgb(0 0 0 / 60%);
    }

    .calender-icon {
        width: 1.5rem;
        height: auto;
        cursor: pointer;
        margin-right: 0.5rem;
    }

    .input-field {
        border: 1px solid #d9d9d9;
        margin: 0.5rem 0;
        border-radius: 6px;
        padding: 0.3rem 0.5rem;

        input {
            font-family: Poppins, sans-serif;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 16px;
            color: #858585;
        }
    }

    .delete-title {
        font-family: Poppins, sans-serif;
        font-weight: 600;
        font-size: 0.85rem;
        margin: 0.5rem 0;
        line-height: 16px;
        color: rgb(0 0 0 / 60%);
    }

    .value {
        font-family: Poppins, sans-serif;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 16px;
        color: #858585;
    }

    .btn {
        background: #6859f9;
        border-radius: 3px;
        margin-left: 0.5rem;
        margin-top: 1rem;
        text-transform: capitalize;
        font-family: Poppins, sans-serif;
        font-weight: 500;
        font-size: 1rem;
        line-height: 16px;
        color: #fff;

        &:hover,
        &:active {
            background: #6859f9 !important;
            color: #fff !important;
        }
    }
}
