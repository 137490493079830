@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap');

.home__container {
    background: #6959f9;
    position: relative;
    font-family: 'Lato', sans-serif;
    .panel-one {
        margin: auto;
        background: url("https://equippp-assets.s3.ap-south-1.amazonaws.com/images/bg-map.png");
        background-size: 90%;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;
        padding: 0 10%;
        min-height: 100vh;
        padding-top: 15vh;

        p {
            font-style: normal;
            text-align: center;
            color: #fff;
        }

        .title {
            font-weight: 400;
            font-size: 2.3rem;
            text-align: center;
        }

        .description {
            font-weight: 300;
            font-size: 1rem;
            margin-top: 0.5rem;
        }

        .project-btn {
            font-weight: 400;
            font-size: 0.8rem;
            min-width: 11rem;
            height: 3.5rem;
            background: #5344e0;
            color: #fff;
            box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
            border-radius: 17.1203px;
            text-transform: capitalize;
            cursor: pointer;
        }
    }

    .panel-two {
        background-color: white;

        .window-img {
            .blur-hash,
            img {
                width: 95%;
                max-width: 1500px;
                margin: auto;
                position: relative;
                bottom: 40vh;
            }

            height: 100vh;
        }
    }

    .panel-top {
        .panel-three {
            background-image: url("https://equippp-assets.s3.ap-south-1.amazonaws.com/images/some-count-that-matter-background.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            padding-top: 1rem;
            padding-bottom: 2rem;

            .count__section {
                border-right: 1px solid #ffffff4f;
                min-width: 17rem;

                &:nth-child(3n + 3) {
                    border-right: 0;
                }
            }

            p {
                opacity: 1;
                font-style: normal;
                text-align: center;
                color: #fff;
            }

            .count__title {
                font-weight: 500;
                font-size: 2.2rem;
            }

            .count__sub-title {
                font-weight: 400;
                font-size: 0.9rem;
                margin-bottom: 1rem;
            }

            .count__text {
                font-weight: 700;
                font-size: 2.5rem;
            }

            .count__data {
                font-weight: 300;
                font-size: 0.7rem;
            }
        }

        .panel-four {
            width: 100%;
            margin: auto;
            background: #f9fafc;

            p {
                font-style: normal;
                font-feature-settings: "salt" on, "liga" off;
            }

            .inner__section {
                //width: 80%;
                // margin:auto;
                margin: 2rem 0rem;
                .step__title {
                    font-weight: 800;
                    text-align: center;
                    font-size: 2.2rem;
                    color: #444;
                }

                .step__sub-title {
                    text-align: center;
                    font-weight: 600;
                    font-size: 1rem;
                    color: #373737;
                    width:80%
                }
            }

            .steps {
                margin-bottom: 2rem;
                padding:0rem 1rem;
                .step__section {
                    min-height: 16rem;
                    background: #fff;
                    border-radius: 8px;
                    margin:0rem 0.7rem;
                    //margin-right: 0.9rem;
                    padding: 1rem;

                    &:nth-child(3n + 4) {
                        margin-right: 0;
                    }

                    .step__text {
                        font-weight: 600;
                        font-size: 1.3rem;
                        margin-top: 1rem;
                        margin-bottom: 0.5rem;
                        color: #18191f;
                    }

                    .step__data {
                        font-weight: 400;
                        font-size: 1rem;
                        color: #18191f;
                    }
                }
            }
        }

        .panel-five {
            //padding-top: 2rem;
            background: #f9fafc;

            .inner_section {
                width: 70%;
                margin: auto;
            }

            .title {
                font-style: normal;
                font-weight: 800;
                font-size: 2rem;
                text-align: center;
                //margin-top: 1rem;
                margin-bottom: 2rem;
                color: #444;
            }

            .projects {
                gap: 0.75rem;

                .project__section {
                    max-width: 218px;
                    background: #fff;
                    border-radius: 8px;
                    padding: 0.6rem;

                    .project__text {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 0.9rem;
                        color: #18191f;
                        padding: 0.5rem;
                    }

                    img {
                        width: 5.1rem;
                    }
                }
            }
        }

        .panel-six {
            padding-top: 2rem;
            padding-bottom: 2rem;
            img {
                width: 80%;
                height: auto;
            }
            background: #f9fafc;

            .inner-content {
                border-radius: 1.4rem;
                background-color: #fff;
                position: relative;
                overflow: hidden;
                margin-bottom: 5rem;

                .ellipse {
                    position: absolute;
                    width: 130px;
                    height: 130px;
                    left: -53px;
                    top: -47px;
                    border-radius: 50%;
                    background: #6859f9;
                }

                .row1-icons {
                    padding: 2rem;

                    .row1-icon {
                        width: 6rem;
                        height: auto;
                        margin: 0.5rem 2rem;
                    }
                }

                .social-project__btn__container {
                    padding: 2rem;

                    .social-project__btn {
                        background: #010088;
                        border-radius: 12px;
                        text-transform: capitalize;
                        font-family: Poppins;
                        font-weight: 600;
                        font-size: 1rem;
                        padding: 0.5rem 1.5rem;
                        margin: 2rem 0.5rem;
                        color: #fff;

                        &.blur {
                            background: #6859f9;
                        }
                    }
                }

                .row2-icons {
                    padding: 2rem;

                    .row2-icon {
                        width: 5rem;
                        height: auto;
                        margin: 0.5rem 2rem;
                    }
                }
            }
        }
    }
}
