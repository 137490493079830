.Terms {
    padding: 45px 0;
    background: #F1F1F1;

    .container {
        background: #F1F1F1;
        width: 100%;

        .panel {
            background: #F1F1F1;
            border-color: #ddd !important;
            box-shadow: 0 1px 3px rgb(0 0 0 / 13%) !important;

            .text-title {
                border-bottom: #ea3460 solid 2px;
                text-align: center;
                font-size: 30px;
                background: #e33066 !important;
                padding: 20px 10px;
                font-weight: 400;
                color: #fff;
            }

            .panel-body {
                padding: 20px 20px;
                background: #fff;
            }

            p {
                padding: 2px 0;
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .container {
            width: 60%;
        }
    }

    @media only screen and (max-width: 900px) {
        .container {
            width: 70%;
        }
    }

    @media only screen and (max-width: 800px) {
        .container {
            width: 80%;
        }
    }

    @media only screen and (max-width: 700px) {
        .container {
            width: 85%;
        }
    }

    @media only screen and (max-width: 600px) {
        .container {
            width: 90%;
        }
    }

    @media only screen and (max-width: 500px) {
        .container {
            width: 95%;
        }
    }
}