footer {
    padding: 2rem 0;
    width: 100%;
    margin: auto;
    background: #000;
    position:relative;


    .copyright__text {
        font-style: normal;
        font-weight: 400;
        font-size: 0.9rem;
        color: #d9dbe1;
        white-space: nowrap;
    }

    .copyright__section {
        width: 75%;
        padding: 0.7rem 0.5rem;
    }

    .footer__section {
        height: fit-content;
        .social__links {
            img {
                margin-left: 0.8rem;
                width: 3rem;
            }
        }

        .footer__logo {
            img {
                float: right;
            }
        }

        .links {
            margin-top: 1.5rem;

            a {
                margin-right: 1.85rem;
                text-decoration: none;
                font-style: normal;
                font-weight: 400;
                font-size: 0.9rem;
                color: #ffffffab;
            }
        }

        .footer-divider {
            width: 75%;
            border: 1px solid #fff;
            opacity: 0.2;
        }
    }
}
