.card_wrapper {
    height: 100%;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 13.3113px 19.967px 3.32784px rgba(90, 82, 128, 0.12);
    border-radius: 21.6309px;
    padding: 1.5rem;
    position: relative;

    .card_flag_image {
        position: absolute;
        top: -24px;
        right: -12px;
        border-radius: 25px;
        object-fit: fill;
    }

    .partner_name {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 38px;
    }
    .partner_desc {
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 20px;
        color: #858585;
        margin-top: 0.8rem;
        margin-bottom: 2rem;
    }
    .total_members_count {
        margin-right: 0.5rem;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 30px;
    }
}
