.recent-activity__container {
    background: #ffffff;
    border-radius: 6.93805px;
  
    border: 1px solid #f3f4f6;
 
    max-height: 500px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    .recent-activity__title {
        font-family:Lato;
        font-weight: 500;
        font-size: 0.8rem;
        line-height: 24px;
        padding: 1rem 2rem;
        color: #494949;
    }
    .recent-activity__item {
        padding: 1rem 2rem;
        .ok-sign {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
    .info-item {
        width: 80%;
    }
    .divider {
        width: 100%;
        border-color: rgba(51, 51, 51, 0.5);
    }
    .activity-date {
        font-family: Lato;
        font-weight: 600;
        font-size: 0.85rem;
        line-height: 21px;
        color: #000000;
    }
    .activity-info {
        font-family: Lato;
        font-weight: 400;
        font-size: 0.85rem;
        line-height: 21px;
        color: #000000;
    }
}
